import dynamic from "next/dynamic";
import Head from "next/head";
import React, { useEffect } from "react";
import { FrontPagesCTA, FrontPagesFooter, FrontPagesLearnMore } from "../components/front-pages/commons";
import AnimatedHeaderText from "../components/front-pages/commons/animated-header-text";
import FrontPagesLayout from "../components/front-pages/front-pages-layout";
import { HomePageFeatures, HomePageItemsToSell, HomePageHeader } from "../components/front-pages/home-page";
import { PricingTestimonials } from "../components/front-pages/pricing";
import { PageTags } from "../components/seo-tags";
import PagesLoaderContext from "../contexts/page-loader-context";
import { GetServerSideProps } from "next";
import fetchStoreData from "@/assets/js/utils/fetch-store-data";
import { StoreFrontMain, StoreFrontProps } from "./[slug]";
import { NotFoundMain } from "./404";
import fetchStoreByDomain from "@/assets/js/utils/fetch-store-by-domain";

const pageTags: PageTags = {
  title: "Catlog - Sell Easily On Social Media",
  description:
    "Catlog gives businesses the tools to take orders via chat, manage their orders & customers, collect payments easily and deliver items without hassle",
  pageUrl: "/",
  image: "https://res.cloudinary.com/catlog/image/upload/v1671181798/seo-banners/Main_Banner.png",
};

interface PageProps {
  config: "store" | "landing";
  data?: StoreFrontProps;
}

const HomePage: React.FC<PageProps> = (props) => {
  const { config, data } = props;

  if (config === "landing") {
    return <LandingPage />;
  }

  if (!data) {
    return <NotFoundMain />;
  }

  return <StoreFrontMain {...data} />;
};

const LandingPage = () => {
  return (
    <PagesLoaderContext.Provider>
      <FrontPagesLayout tags={pageTags}>
        <Head>
          <link rel="icon" href="/favicon.png" type="image/png" />
          <link
            rel="preload"
            as="image"
            href=""
            imageSrcSet="https://res.cloudinary.com/catlog/image/upload/c_scale,w_750/v1670354764/front-pages/woman-with-cup.webp 750w, https://res.cloudinary.com/catlog/image/upload/c_scale,w_1200/v1670354764/front-pages/woman-with-cup.webp 1200w"
            sizes="(max-width: 520px) 750px, 1200px"
          ></link>
        </Head>
        <main>
          <HomePageHeader />
          <HomePageFeatures />
          <PricingTestimonials whiteBg />
          <HomePageItemsToSell />
          <FrontPagesLearnMore />
          <FrontPagesCTA className="primary-cta">
            <AnimatedHeaderText
              series={[
                ["Get", "started", "in"],
                ["5", "minutes"],
              ]}
              className="!leading-[1]"
            />
          </FrontPagesCTA>
          <FrontPagesFooter />
        </main>
      </FrontPagesLayout>
    </PagesLoaderContext.Provider>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const host = context.req.headers.host;
  const query = context.query;
  const domain = process.env.NEXT_PUBLIC_APP_URL.replace(/^https?:\/\//, "");

  // First, check if this is the main domain
  if (host === domain || host === `www.${domain}`) {
    return {
      props: {
        config: "landing",
        data: null,
      },
    };
  }

  // Check if it's a subdomain using our regular pattern
  const wildcard = host.split(".")[0];
  const isSubdomain = host.replace(`${wildcard}.`, "") === domain;

  if (isSubdomain) {
    // Handle subdomain case (existing logic)
    if (wildcard === "www") {
      return {
        props: {
          config: "landing",
          data: null,
        },
      };
    }

    // Prevent second level subdomains
    if (host.replace(`${wildcard}.`, "") !== domain) {
      return {
        props: {
          config: "store",
          data: null,
        },
      };
    }

    const data = await fetchStoreData({ slug: wildcard, query });

    return {
      props: {
        config: "store",
        data: data.props,
      },
    };
  }

  // It's a custom domain - fetch the store by domain
  try {
    // Update the fetch-store-data utility to support custom domains
    const data = await fetchStoreByDomain({ domain: host, query });

    return {
      props: {
        config: "store",
        data: data.props,
      },
    };
  } catch (error) {
    console.error("Error fetching store by domain:", error);
    return {
      props: {
        config: "store",
        data: null,
      },
    };
  }
};

export default HomePage;
